import { Box } from "@mui/material";
import { grey, orange } from "@mui/material/colors";
import React from "react";

interface LayoutProps {
  children: React.ReactNode;
}

export function Layout({ children }: LayoutProps) {
  return (
    <Box>
      <Box
        sx={{
          borderBottom: `1px solid ${grey[200]}`,
          paddingTop: 2,
          paddingBottom: 2,
          paddingLeft: 4,
          paddingRight: 4,
        }}
      >
        <img src="logo.png" width="auto" height={60} alt="logo"></img>
      </Box>
      <Box
        sx={{
          zIndex: -1,
          minHeight: "calc(100vh - 224px)",
          backgroundColor: orange[50],
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 12,
          paddingRight: 12,
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
