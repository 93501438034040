import { Card, CardContent } from "@mui/material";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useRequestBindEmail } from "../../hooks/useRequestBindEmail";
import { ContactSelectSuccess } from "./ContactSelectSuccess";
import LoadingCrmData from "../../components/LoadingCrmData";

export function ContactSelect() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const fullName = queryParams.get("fullName");
  const email = queryParams.get("email");
  const account = queryParams.get("account");
  const id = queryParams.get("id");
  const code = queryParams.get("code")!;
  const isSecondary = queryParams.get("isSeconary")! === "true";
  const isLead = queryParams.get("isLead") === "true";
  const wasCalled = useRef(false);
  const { loading, error, requestBindEmail } = useRequestBindEmail();

  useEffect(() => {
    if (wasCalled.current) {
      return;
    }

    requestBindEmail({
      code,
      contactId: id!,
      email: email!,
      isLead: isLead,
      isSecondary,
    });

    wasCalled.current = true;
  }, [
    fullName,
    email,
    account,
    requestBindEmail,
    id,
    code,
    isLead,
    isSecondary,
  ]);

  return (
    <Card elevation={4} sx={{ width: 500, margin: "auto" }}>
      <CardContent>
        {!loading && !error && (
          <ContactSelectSuccess fullName={fullName!} account={account!} />
        )}
        {loading && <LoadingCrmData />}
      </CardContent>
    </Card>
  );
}
