import { Typography, Divider, Box } from "@mui/material";
import { green, grey } from "@mui/material/colors";

interface Props {
  fullName: string;
  account: string;
}

export function ContactSelectSuccess({ fullName, account }: Props) {
  return (
    <>
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <img alt="sucess" src="/success.jpg" height={200} width="auto" />
      </Box>

      <Typography
        gutterBottom
        variant="h1"
        sx={{
          color: green[500],
          marginTop: 2,
          marginBottom: 3,
          textAlign: "center",
        }}
      >
        Success
      </Typography>

      <Divider />

      <Typography
        sx={{ marginTop: 3, marginBottom: 1, color: grey[600] }}
        gutterBottom
        variant="body1"
      >
        Notes will be created for
      </Typography>
      <Typography variant="h2">{fullName}</Typography>
      <Typography variant="h3" sx={{ color: grey[600], marginTop: 1 }}>
        {account}
      </Typography>

      <Typography sx={{ paddingTop: 5, color: grey[500] }} variant="body1">
        You may close this page now. Notes will be uploaded to CRM within a few
        minutes.
      </Typography>
    </>
  );
}
