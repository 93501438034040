import React from "react";
import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Typography,
  Box,
  Paper,
} from "@mui/material";

interface Props {
  primaryEmail: string;
  secondaryEmail: string;
  newEmail: string;
  selectedOption: string;
  setSelectedOption: (option: string) => void;
}

const EmailRewriteOptions: React.FC<Props> = ({
  primaryEmail,
  secondaryEmail,
  newEmail,
  selectedOption,
  setSelectedOption,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption((event.target as HTMLInputElement).value);
  };

  return (
    <FormControl component="fieldset" sx={{ width: "100%" }}>
      <RadioGroup row value={selectedOption} onChange={handleChange}>
        <Box
          component={Paper}
          padding={2}
          marginRight={2}
          sx={{
            flexGrow: "1",
            cursor: "pointer",
            border: `1px solid ${
              selectedOption === "primary" ? "orange" : "transparent"
            }`,
          }}
          flex={1}
          onClick={() => setSelectedOption("primary")}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h4">
              {primaryEmail ? "Update" : "Add"} Primary Email
            </Typography>
            <FormControlLabel
              sx={{ ml: 0 }}
              value="primary"
              control={<Radio />}
              label={<Typography variant="body1"></Typography>}
              labelPlacement="start"
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
            {primaryEmail && (
              <Typography variant="body1" color="textPrimary">
                Replacing
              </Typography>
            )}
            {primaryEmail && (
              <Typography variant="body2" color="textSecondary">
                {primaryEmail}
              </Typography>
            )}
            {!primaryEmail && (
              <Typography variant="body1" color="textPrimary">
                Previously not set
              </Typography>
            )}
          </Box>
        </Box>

        <Box
          component={Paper}
          padding={2}
          display="flex"
          sx={{
            flexGrow: "1",
            cursor: "pointer",
            border: `1px solid ${
              selectedOption !== "primary" ? "orange" : "transparent"
            }`,
          }}
          flexDirection="column"
          flex={1}
          onClick={() => setSelectedOption("secondary")}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h4">
              {secondaryEmail ? "Update" : "Add"} Secondary Email
            </Typography>
            <FormControlLabel
              sx={{ ml: 0 }}
              value="secondary"
              control={<Radio />}
              label={<Typography variant="body1"></Typography>}
              labelPlacement="start"
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
            {secondaryEmail && (
              <Typography variant="body1" color="textPrimary">
                Replacing
              </Typography>
            )}
            {secondaryEmail && (
              <Typography variant="body2" color="textSecondary">
                {secondaryEmail}
              </Typography>
            )}
            {!secondaryEmail && (
              <Typography variant="body1" color="textPrimary">
                Previously not set
              </Typography>
            )}
          </Box>
        </Box>
      </RadioGroup>
    </FormControl>
  );
};

export default EmailRewriteOptions;
