// eslint-disable-next-line import/no-anonymous-default-export
export default {
  values: {
    xs: 100,
    sm: 480,
    md: 769,
    lg: 1080,
    xl: 1400,
  },
};
