import React, { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import axios, { AxiosResponse } from "axios";
import { debounce } from "lodash";
import { ZohoAccount } from "../../types/zoho";
import { LAMBDA_BASE_URL } from "../../constants";

interface Props {
  onChange: (account: ZohoAccount) => void;
}

const AccountSearchBox: React.FC<Props> = ({ onChange }) => {
  const [results, setResults] = useState<ZohoAccount[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  // Function to handle the API request
  const fetchResults = async (searchQuery: string) => {
    if (!searchQuery) return;
    setLoading(true);
    try {
      const response = await axios.get<any>(LAMBDA_BASE_URL, {
        params: { searchAccount: searchQuery },
      });
      // const response = (await new Promise((resolve) =>
      //   setInterval(
      //     () =>
      //       resolve({
      //         data: [
      //           { id: 1, name: "Logi" },
      //           { id: 2, name: "Asana" },
      //         ],
      //       }),
      //     1000
      //   )
      // )) as unknown as AxiosResponse;
      setResults(response.data);
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchResults = debounce(fetchResults, 500);

  const handleInputChange = (event: React.SyntheticEvent, value: string) => {
    debouncedFetchResults(value);
  };

  const handleOptionSelect = (
    event: React.SyntheticEvent,
    value: string | ZohoAccount | null
  ) => {
    if (value) {
      onChange(value as ZohoAccount);
    }
  };

  return (
    <Autocomplete
      freeSolo
      loading={loading}
      options={results}
      filterOptions={(options) => options}
      getOptionLabel={(option) => (option as ZohoAccount).Account_Name}
      onInputChange={handleInputChange}
      onChange={handleOptionSelect}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Account"
          fullWidth
        />
      )}
    />
  );
};

export default AccountSearchBox;
