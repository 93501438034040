import React, { useState } from "react";
import { TextField, Button, Box, CircularProgress } from "@mui/material";
import { useLocation } from "react-router-dom";
import AccountSearchBox from "./AccountSearchBox";
import { ContactDetails, ZohoAccount } from "../../types/zoho";
import axios from "axios";
import { LAMBDA_BASE_URL } from "../../constants";
interface Props {
  onSuccess: (contactDetails: ContactDetails) => void;
}

export function CreateContact({ onSuccess }: Props) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const fullName = queryParams.get("fullName") || "";
  const code = queryParams.get("code") || "";
  const email = queryParams.get("email") || "";

  // State variables for form inputs
  const [firstName, setFirstName] = useState<string>(
    fullName ? fullName.split(" ")[0] : ""
  );
  const [isLoading, setIsLoading] = useState(false);
  const [account, setAccount] = useState<ZohoAccount | null>(null);
  const [lastName, setLastName] = useState<string>(
    fullName ? fullName.split(" ")[1] : ""
  );
  const [primaryEmail, setPrimaryEmail] = useState<string>(email);
  const [secondaryEmail, setSecondaryEmail] = useState<string>("");

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await axios.post<any>(
        LAMBDA_BASE_URL,
        {
          lastName,
          firstName,
          email: primaryEmail,
          secondaryEmail,
          accountName: account!.Account_Name,
        },
        {
          params: {
            code,
            isCreate: true,
          },
        }
      );
      setIsLoading(false);
      onSuccess({
        fullName: `${firstName} ${lastName}`,
        account: account!.Account_Name,
      });
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box sx={{ mt: 3 }} display="flex" flexDirection="column" gap={2}>
          <TextField
            disabled={isLoading}
            label="First Name"
            variant="outlined"
            fullWidth
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <TextField
            disabled={isLoading}
            label="Last Name"
            variant="outlined"
            fullWidth
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <TextField
            disabled={isLoading}
            label="Primary Email"
            variant="outlined"
            fullWidth
            value={primaryEmail}
            onChange={(e) => setPrimaryEmail(e.target.value)}
          />
          <TextField
            disabled={isLoading}
            label="Secondary Email"
            variant="outlined"
            fullWidth
            value={secondaryEmail}
            onChange={(e) => setSecondaryEmail(e.target.value)}
          />
          <AccountSearchBox
            onChange={(account: ZohoAccount) => setAccount(account)}
          />
          <Button
            disabled={isLoading}
            type="submit"
            variant="contained"
            color="primary"
          >
            {isLoading ? <CircularProgress /> : "Create"}
          </Button>
        </Box>
      </form>
    </>
  );
}
