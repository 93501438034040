import { Box, Button } from "@mui/material";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useRequestBindEmail } from "../../hooks/useRequestBindEmail";
import LoadingCrmData from "../LoadingCrmData";
import { ContactSelectSuccess } from "../../pages/ContactSelect/ContactSelectSuccess";
import ContactSearchBox from "./ContactSearchBox";
import EmailRewriteOptions from "./EmailRewriteOptions";
import { ContactDetails, ZohoContact } from "../../types/zoho";
import axios from "axios";
import { LAMBDA_BASE_URL } from "../../constants";

interface Props {
  onSuccess: (contactDetails: ContactDetails) => void;
}

export function FindContact({ onSuccess }: Props) {
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState<string>("primary");
  const [isLoading, setIsLoading] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const fullName = queryParams.get("fullName");
  const email = queryParams.get("email");
  const code = queryParams.get("code");
  const [existingContact, setExistingContact] = useState<ZohoContact | null>(
    null
  );

  const { loading, requestBindEmail, contactData } = useRequestBindEmail();

  const assignAccount = async () => {
    const data: any = {
      id: existingContact?.id,
    };
    if (selectedOption === "primary") {
      data.email = email;
    } else {
      data.secondaryEmail = email;
    }
    try {
      await axios.post<any>(LAMBDA_BASE_URL, data, {
        data,
        params: {
          code,
          isUpdate: true,
        },
      });
      setIsLoading(false);
      onSuccess({
        fullName: existingContact?.fullName!,
        account: existingContact?.account!,
      });
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  return (
    <>
      {!loading && !contactData && (
        <Box sx={{ mt: 3 }}>
          <ContactSearchBox
            setContact={setExistingContact}
            initialValue={fullName?.trim() ?? ""}
          />
          {existingContact && (
            <Box sx={{ mt: 2, width: "100%" }}>
              <EmailRewriteOptions
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                primaryEmail={existingContact.email}
                secondaryEmail={""}
                newEmail={email!}
              />
            </Box>
          )}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              sx={{ marginTop: 4, width: "100%" }}
              variant="contained"
              size="large"
              onClick={assignAccount}
              disabled={existingContact === null}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      )}
      {loading && <LoadingCrmData />}
      {!loading && contactData && <ContactSelectSuccess {...contactData} />}
    </>
  );
}
