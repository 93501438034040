import { useState, useCallback } from "react";
import { ZohoContact } from "../types/zoho";
import axios from "axios";
import { LAMBDA_BASE_URL } from "../constants";
export function useRequestBindEmail() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [contactData, setContactData] = useState<ZohoContact | null>(null);

  const requestBindEmail = useCallback(
    async ({
      code,
      contactId,
      email,
      isLead,
      isSecondary,
    }: {
      code: string;
      contactId: string;
      email: string;
      isLead: boolean;
      isSecondary: boolean;
    }) => {
      try {
        setLoading(true);
        setError(null);
        const response = await axios.get(LAMBDA_BASE_URL, {
          params: { code, contactId, email, isLead, isSecondary },
        });
        if (response.status !== 200) {
          throw new Error(response.data);
        }
        setContactData(response.data);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return { loading, error, requestBindEmail, contactData };
}
