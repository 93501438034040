import React, { useState, useCallback } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { debounce } from "lodash";
import { LAMBDA_BASE_URL } from "../../constants";
import { ZohoContact } from "../../types/zoho";
import { Box, Typography } from "@mui/material";

interface Props {
  setContact: (id: ZohoContact) => void;
  initialValue: string;
}

const ContactSearchBox: React.FC<Props> = ({ setContact, initialValue }) => {
  const [results, setResults] = useState<ZohoContact[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState("");

  // Function to handle the API request
  const fetchResults = async (searchQuery: string) => {
    if (!searchQuery) return;
    setLoading(true);
    try {
      const response = await axios.get<any>(LAMBDA_BASE_URL, {
        params: { search: searchQuery },
      });
      setResults(response.data);
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchResults = useCallback(debounce(fetchResults, 500), []);

  const handleInputChange = (event: React.SyntheticEvent, value: string) => {
    setInputValue(value);
    debouncedFetchResults(value);
  };

  const handleOptionSelect = (
    event: React.SyntheticEvent,
    value: string | ZohoContact | null
  ) => {
    if (value) {
      setContact(value as ZohoContact);
    }
  };

  console.log(results);

  return (
    <Autocomplete
      freeSolo
      loading={loading}
      options={results}
      getOptionLabel={(option) =>
        `${(option as ZohoContact).fullName} - ${
          (option as ZohoContact).account
        }`
      }
      renderOption={(props, option) => {
        return (
          <li key={option.id} {...props}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="body1">
                {(option as ZohoContact).fullName}
              </Typography>
              <Typography variant="body2">
                {(option as ZohoContact).account}
              </Typography>
            </Box>
          </li>
        );
      }}
      onInputChange={handleInputChange}
      onChange={handleOptionSelect}
      inputValue={inputValue}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Contact"
          variant="outlined"
          placeholder="Search by full name, or email"
          fullWidth
        />
      )}
    />
  );
};

export default ContactSearchBox;
