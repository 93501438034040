import { createTheme } from '@mui/material/styles';
import './global-styles.css';
import palette from './palette';
import breakpoints from './breakpoints';
import typography from './typography';
import overrides from './overrides';

import '@emotion/react';
import { Theme as MaterialUITheme } from '@mui/material';
export * from './variables';

declare module '@mui/material/styles' {
  interface Palette {
    brand: {
      primary: string;
      highlight: string;
      secondary: string;
    };
    highlight: {
      main: string;
      dark: string;
      hover: string;
      error: string;
      success: string;
      neutral: string;
    };
  }

  interface PaletteOptions {
    brand: {
      primary: string;
      highlight: string;
      secondary: string;
    };
    highlight: {
      main: string;
      dark: string;
      hover: string;
      error: string;
      success: string;
      neutral: string;
    };
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    highlight: true;
  }
}

declare module '@emotion/react' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface Theme extends MaterialUITheme {}
}

export const muiTheme = createTheme({
  palette,
  breakpoints,
  typography,
  spacing: (factor: number) => factor * 8,
  components: overrides,
});
