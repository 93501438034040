export const avatarLargeSize = 56;
export const avatarMediumSize = 40;
export const avatarSmallSize = 24;
export const drawerWidth = 220;
export const fadeInDuration = 450;
export const footerHeight = 60;
export const approvalsHeaderHeight = 66;
export const approvalsMetricHeaderHeight = 38;
export const approvalsMetricStickyTopOffset =
  approvalsHeaderHeight + approvalsMetricHeaderHeight;
export const tableInputWidth = 72;
export const tableRowHeight = 72;
export const avatarRadius = '30%';
export const borderRadius = '8px';
export const borderRadiusSmall = '4px';

export const fontSizeRegular = 14;
export const fontSizeSmall = 13;
export const fontSizeXSmall = 11;

export const fontSizeH6 = fontSizeSmall;
export const fontSizeH5 = fontSizeRegular;
export const fontSizeH4 = fontSizeRegular;
export const fontSizeH3 = 18;
export const fontSizeH2 = 24;
export const fontSizeH1 = 32;

export const fontWeightBold = 500;
