import { PaletteOptions } from "@mui/material/styles/createPalette";
import { orange, amber, red, lightGreen, blue } from "@mui/material/colors";

export default {
  background: {
    default: "#F6F8FC",
  },
  primary: {
    main: orange["A200"],
  },
  action: {
    hover: blue[50],
  },
  highlight: {
    main: amber[100],
    dark: amber[500],
    hover: amber[200],
    error: red[50],
    success: lightGreen[50],
    neutral: "#F6F8FC",
  },
  warning: {
    main: orange[600],
    light: orange[400],
    dark: orange[800],
  },
  brand: {
    primary: orange[600],
    highlight: orange[800],
    secondary: orange[300],
  },
} as PaletteOptions;
