import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import { muiTheme } from "./theme";
import { ContactSelect } from "./pages/ContactSelect";
import { Layout } from "./layouts";
import { ThemeProvider } from "@emotion/react";
import AssignContact from "./pages/AssignContact/AssignContact";

const router = createBrowserRouter([
  {
    path: "/select-contact",
    element: <ContactSelect />,
  },
  {
    path: "/assign-contact",
    element: <AssignContact />,
  },
  {
    path: "*",
    element: <h1>This page does not exist</h1>,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={muiTheme}>
      <Layout>
        <RouterProvider router={router} />
      </Layout>
    </ThemeProvider>
  </React.StrictMode>
);
