import { Typography, Divider } from "@mui/material";
import { grey } from "@mui/material/colors";

interface Props {
  email: string;
  fullName: string;
}

export default function FindContactInfo({ email, fullName }: Props) {
  return (
    <>
      <Typography
        sx={{ marginBottom: 2, color: grey[600] }}
        gutterBottom
        variant="body1"
      >
        No matching contacts were found
      </Typography>
      <Typography variant="h2">{email}</Typography>
      <Typography
        variant="h3"
        sx={{ paddingTop: 1, paddingBottom: 4, color: grey[600] }}
      >
        {fullName}
      </Typography>
      <Divider />
      <Typography
        sx={{ marginTop: 2, marginBottom: 2, color: grey[800] }}
        gutterBottom
        variant="h4"
      >
        Select an existing Contact to update its email or create a new one
      </Typography>
    </>
  );
}
