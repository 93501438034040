import React, { useState } from "react";
import { Box, Tabs, Tab, Paper } from "@mui/material";

interface TabContentProps {
  [key: string]: React.ReactNode;
}

interface SwitcherComponentProps {
  tabs: { value: string; label: string }[];
  tabContents: TabContentProps;
}

const SwitcherComponent: React.FC<SwitcherComponentProps> = ({
  tabs,
  tabContents,
}) => {
  const [selectedTab, setSelectedTab] = useState<string>(tabs[0]?.value || "");

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ width: "100%", margin: "auto", mt: 1 }}>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        aria-label="Switcher Tabs"
        centered
        variant="fullWidth"
      >
        {tabs.map((tab) => (
          <Tab key={tab.value} value={tab.value} label={tab.label} />
        ))}
      </Tabs>

      {tabContents[selectedTab]}
    </Box>
  );
};

export default SwitcherComponent;
