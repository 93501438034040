import { Box, CircularProgress, Typography } from "@mui/material";

export default function LoadingCrmData() {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: 2,
          height: 300,
        }}
      >
        <CircularProgress />
        <Typography variant="h5" color="primary" sx={{ textAlign: "center" }}>
          Uploading data to CRM
        </Typography>
      </Box>
    </>
  );
}
