import React, { useState } from "react";
import { Box, Tabs, Tab, Paper, Card, CardContent } from "@mui/material";
import FindContactInfo from "../../components/FindContact/FindContactInfo";
import { useLocation } from "react-router-dom";
import { FindContact } from "../../components/FindContact";
import { CreateContact } from "../../components/CreateContact/CreateContact";
import SwitcherComponent from "../../components/SwitcherComponent";
import { ContactSelectSuccess } from "../ContactSelect/ContactSelectSuccess";
import { ContactDetails } from "../../types/zoho";

const AssignContact: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const fullName = queryParams.get("fullName") ?? "";
  const email = queryParams.get("email") ?? "";

  const [isCreateSuccess, setIsCreateSuccess] = useState(false);
  const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);

  const [contactDetails, setContactDetails] = useState<ContactDetails | null>(
    null
  );

  const onUpdateSuccess = ({
    fullName,
    account,
  }: {
    fullName: string;
    account: string;
  }) => {
    setContactDetails({ fullName, account });
    setIsUpdateSuccess(true);
  };

  const onCreateSuccess = ({
    fullName,
    account,
  }: {
    fullName: string;
    account: string;
  }) => {
    setContactDetails({ fullName, account });
    setIsCreateSuccess(true);
  };

  const tabs = [
    { value: "search", label: "Search" },
    { value: "create", label: "Create New" },
  ];

  const tabContents = {
    search: <FindContact onSuccess={onUpdateSuccess} />,
    create: <CreateContact onSuccess={onCreateSuccess} />,
  };

  return (
    <Card elevation={5} sx={{ width: 500, margin: "auto" }}>
      <CardContent>
        {isCreateSuccess ? (
          <ContactSelectSuccess
            fullName={contactDetails?.fullName!}
            account={contactDetails?.account!}
          />
        ) : isUpdateSuccess ? (
          <ContactSelectSuccess
            fullName={contactDetails?.fullName!}
            account={contactDetails?.account!}
          />
        ) : (
          <>
            <FindContactInfo fullName={fullName} email={email} />
            <SwitcherComponent tabs={tabs} tabContents={tabContents} />
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default AssignContact;
